<template>
  <div>
    <h1>Tabeas Testseite</h1>

    <LernbausteinSchieberegler
      :id="LBSTSchieberegler[0].id"
      :workOrder="LBSTSchieberegler[0].workOrder"
      :originalSlideWidth="LBSTSchieberegler[0].originalSlideWidth"
      :originalSlideHeight="LBSTSchieberegler[0].originalSlideHeight"
      :slides="LBSTSchieberegler[0].slides"
    >
      <template v-slot:alternative-content>
        <video
          preload="auto"
          controls
          width="60%"
          style="margin-left: 20%"
          @play="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @fullscreenchange="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @webkitfullscreenchange="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @mozfullscreenchange="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @msfullscreenchange="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @ended="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @pause="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @seeking="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @enterpictureinpicture="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
          @leavepictureinpicture="
            videoPlayerEvent(
              $event,
              '4er-System um 1 weiterzählen_201103_YB.mp4'
            )
          "
        >
          <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
          <source
            src="@/assets/swvi/4er-System um 1 weiterzählen_201103_YB.mp4"
            type="video/mp4"
          />
        </video>
      </template>
    </LernbausteinSchieberegler>

    <LernbausteinGruppen
      :id="LBSTGruppen[0].id"
      :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
      :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
      :showGroupNames="LBSTGruppen[0].showGroupNames"
      :workOrder="LBSTGruppen[0].workOrder"
      :possibleCategories="LBSTGruppen[0].possibleCategories"
      :elements="LBSTGruppen[0].elements"
      :responses="LBSTGruppen[0].responses"
    >
      <template v-slot:alternative-content>
        <v-simple-table class="imageTable">
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="text-center">
                  <strong>223</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/223_3.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/223_4.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_4.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/223_2.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_2.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/223_1.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/223_1.jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <strong>32</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/32_2.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/32_2.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/32_3.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/32_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/32_4.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/32_4.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/32_1.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/32_1.jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <strong>203</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/203_1.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/203_1.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/203_2.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/203_2.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/203_3.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/203_3.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/203_4.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/203_4.jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <strong>2030</strong>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/2030_1.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/2030_1.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/2030_2.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/2030_2.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/2030_4.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/2030_4.jpg"
                    />
                  </div>
                </td>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="require('@/assets/swve/SDWH2_10er/2030_3.jpg')"
                      contain
                      parentId="SWVI"
                      elementId="SDWH2_10er/2030_3.jpg"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </LernbausteinGruppen>

    <LernbausteinSortieren
      :id="LBSTSortieren[0].id"
      :workOrder="LBSTSortieren[0].workOrder"
      :elements="LBSTSortieren[0].elements"
      :responses="LBSTSortieren[0].responses"
      :left="LBSTSortieren[0].left"
      :right="LBSTSortieren[0].right"
    >
      <template v-slot:alternative-content>
        <video
          preload="auto"
          controls
          width="60%"
          style="margin-left: 20%"
          @play="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
          @fullscreenchange="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
          @webkitfullscreenchange="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
          @mozfullscreenchange="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
          @msfullscreenchange="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
          @ended="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
          @pause="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
          @seeking="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
          @enterpictureinpicture="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
          @leavepictureinpicture="
            videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
          "
        >
          <source
            src="@/assets/swvi/SWV-I_Zahlen_sortieren_201113.mp4"
            type="video/mp4"
          />
        </video>
      </template>
    </LernbausteinSortieren>

    <LernbausteinStempeln
      :id="LBSTStempeln[0].id"
      :workOrder="LBSTStempeln[0].workOrder"
      :image="LBSTStempeln[0].image"
      :audio="LBSTStempeln[0].audio"
      :elements="LBSTStempeln[0].elements"
    >
      <template v-slot:alternative-content-0>
        <v-simple-table class="imageTable">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="
                        require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                      "
                      contain
                      parentId="SWVI"
                      elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                    />
                  </div>
                </td>
                <td>
                  <p>
                    In dieser Abbildung siehst Du, wie Clara drei Aufgabe gelöst
                    hat. Anhand der vierten Aufgabe 29 + 6 wird rekonstruiert,
                    wie Clara beim Lösen der Aufgaben vermutlich vorgegangen
                    ist, über welche Kompetenzen sie bereits verfügt und was wir
                    über die Ursache des Fehlers vermuten können.
                  </p>
                  <p>
                    Betrachtet man die Lösungen der oberen drei Aufgaben, so
                    fällt auf, dass Clara vermutlich jeweils die beiden
                    Summanden stellenweise, dabei eventuell auch die Zehner und
                    Einer ziffernweise, betrachtet und separat addiert. Die
                    dabei herauskommenden Summen notiert sie hintereinander,
                    anstatt sie wiederum stellengerecht miteinander zu addieren.
                    Clara würde bei der Aufgabe 29 + 6 also vermutlich zu dem
                    Ergebnis 215 kommen, indem sie zuerst 2 + 0 = 2,
                    anschließend 9 + 6 = 15 rechnet und beide Teilergebnisse
                    hintereinander aufschreibt.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>

      <template v-slot:alternative-content-1>
        <v-simple-table class="imageTable">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="
                        require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                      "
                      contain
                      parentId="SWVI"
                      elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                    />
                  </div>
                </td>
                <td>
                  <p>
                    Clara verfügt dementsprechend bereits über die Kompetenz,
                    Additionsaufgaben von ein- und zweistelligen Zahlen zu
                    zweistelligen Summen stellenweise zu lösen, solange dabei
                    keine Bündelung erforderlich ist.
                  </p>
                  <p>
                    Die Ursache für die Fehler könnte sein, dass Clara das
                    Prinzip des Stellenwerts und das Prinzip der Bündelung noch
                    nicht verstanden hat. Dass Clara das Prinzip des
                    Stellenwerts noch nicht verstanden hat, kann daran vermutet
                    werden, dass sie zwei Ziffern zur Besetzung eines
                    Stellenwerts notiert. Dies deutet auch auf Probleme mit dem
                    Bündelungsprinzip hin, denn Clara schreibt zwei Ziffern an
                    eine Stelle, da sie keine Bündelung durchführt bzw. Elemente
                    der gleichen Bündelungseinheit (Zehner = Z, Einer = E) nicht
                    zusammenfasst. Clara rechnet 9 E + 6 E = 15 E. Die 15 Einer
                    werden jedoch nicht als 1 Zehner und 5 Einer erkannt bzw.
                    der eine Zehner nicht mit den beiden anderen Zehnern aus der
                    Rechnung 2 Z + 0 Z = 2 Z zusammengefasst.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>

      <template v-slot:alternative-content>
        <v-simple-table class="imageTable">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>
                  <div class="imageTableImages tableImageContainer">
                    <AppExpandableImage
                      :src="
                        require('@/assets/swve/Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png')
                      "
                      contain
                      parentId="SWVI"
                      elementId="Einzeldiagnose/Einzeldiagnose_Rechnung_Clara.png"
                    />
                  </div>
                </td>
                <td>
                  <p>
                    In dieser Abbildung siehst Du, wie Clara drei Aufgabe gelöst
                    hat. Anhand der vierten Aufgabe 29 + 6 wird rekonstruiert,
                    wie Clara beim Lösen der Aufgaben vermutlich vorgegangen
                    ist, über welche Kompetenzen sie bereits verfügt und was wir
                    über die Ursache des Fehlers vermuten können.
                  </p>
                  <p>
                    Betrachtet man die Lösungen der oberen drei Aufgaben, so
                    fällt auf, dass Clara vermutlich jeweils die beiden
                    Summanden stellenweise, dabei eventuell auch die Zehner und
                    Einer ziffernweise, betrachtet und separat addiert. Die
                    dabei herauskommenden Summen notiert sie hintereinander,
                    anstatt sie wiederum stellengerecht miteinander zu addieren.
                    Clara würde bei der Aufgabe 29 + 6 also vermutlich zu dem
                    Ergebnis 215 kommen, indem sie zuerst 2 + 0 = 2,
                    anschließend 9 + 6 = 15 rechnet und beide Teilergebnisse
                    hintereinander aufschreibt.
                  </p>
                  <p>
                    Clara verfügt dementsprechend bereits über die Kompetenz,
                    Additionsaufgaben von ein- und zweistelligen Zahlen zu
                    zweistelligen Summen stellenweise zu lösen, solange dabei
                    keine Bündelung erforderlich ist.
                  </p>
                  <p>
                    Die Ursache für die Fehler könnte sein, dass Clara das
                    Prinzip des Stellenwerts und das Prinzip der Bündelung noch
                    nicht verstanden hat. Dass Clara das Prinzip des
                    Stellenwerts noch nicht verstanden hat, kann daran vermutet
                    werden, dass sie zwei Ziffern zur Besetzung eines
                    Stellenwerts notiert. Dies deutet auch auf Probleme mit dem
                    Bündelungsprinzip hin, denn Clara schreibt zwei Ziffern an
                    eine Stelle, da sie keine Bündelung durchführt bzw. Elemente
                    der gleichen Bündelungseinheit (Zehner = Z, Einer = E) nicht
                    zusammenfasst. Clara rechnet 9 E + 6 E = 15 E. Die 15 Einer
                    werden jedoch nicht als 1 Zehner und 5 Einer erkannt bzw.
                    der eine Zehner nicht mit den beiden anderen Zehnern aus der
                    Rechnung 2 Z + 0 Z = 2 Z zusammengefasst.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </LernbausteinStempeln>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppExpandableImage from "@/common/AppExpandableImage";

import LernbausteinSchieberegler from "@/components/faledia/explorativeElemente/LernbausteinSchieberegler";
import LernbausteinSchieberegler_1_SEBH_1_4er_System from "@/components/faledia/seiten/swve/beispiele/1_SEBH_1_4er_System";

import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import LernbausteinGruppen_SDWH2_10er from "@/components/faledia/seiten/swve/beispiele/SDWH2_10er";

import LernbausteinSortieren from "@/components/faledia/explorativeElemente/LernbausteinSortieren";
import SSZH_2_1 from "@/components/faledia/seiten/swve/beispiele/SSZH_2_1";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import Einzeldiagnose_Clara from "@/components/faledia/seiten/swve/beispiele/Einzeldiagnose_Clara";

export default {
  components: {
    LernbausteinSchieberegler,
    LernbausteinGruppen,
    LernbausteinSortieren,
    LernbausteinStempeln,
    AppExpandableImage,
  },
  data: () => ({
    LBSTSchieberegler: [LernbausteinSchieberegler_1_SEBH_1_4er_System],
    LBSTGruppen: [LernbausteinGruppen_SDWH2_10er],
    LBSTSortieren: [SSZH_2_1],
    LBSTStempeln: [Einzeldiagnose_Clara],
  }),
  methods: {
    audioEnded(id) {
      AppHelper.audioEnded(this, id);
    },

    audioPlayerClicked(event, id, ref) {
      AppHelper.audioPlayerClicked(this, event, id, ref);
    },

    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>

<style>
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}

.tableImageContainer {
  width: 179px;
}

.biggerTableImageContainer {
  width: 390px;
}
</style>